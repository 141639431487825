
import { defineComponent, ref, Ref } from 'vue';
import breadCrumb from '@/components/common/bread-crumb';
import { distributorBaseRouter, distributorSubRouter } from '@/router';
import { distributor } from '@/data';
import { list, RequestData } from '@/components/common/list';
import { getInfo } from './util';

const navHeaders = [{ label: WordList.PersonuserInfo }];
const deviceHeaders = [{
    label: WordList.TabelPersonDeviceInHtmlLocation,
    name: 'Location'
}, {
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}];
const userHeaders = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}];
const basePath = `/${distributorBaseRouter}/${distributorSubRouter.user}?type=endUser`;
export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
        role: {
            type: String,
            required: true
        }
    },
    components: {
        breadCrumb,
        list
    },
    setup(props) {
        const { info } = getInfo(props.id, props.role);

        // 获取设备
        const devRequestData: RequestData = {
            url: (props.role === '10' || props.role === '11') ? 'v3/web/single/device/getOwnerList' : 'v3/web/community/device/getOwnerList',
            param: {
                ID: props.id
            }
        };
        // 获取成员
        const userRequestData: RequestData = {
            url: (props.role === '10' || props.role === '11') ? 'v3/web/single/user/getSubList' : 'v3/web/community/user/getSubList',
            param: {
                ID: props.id
            }
        };
        return {
            navHeaders,
            basePath,
            distributor,
            info,
            deviceHeaders,
            userHeaders,
            devRequestData,
            userRequestData
        };
    }
});
